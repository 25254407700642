<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA契約" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <!--契約情報-->
        <div class="l-contents l-box">
          <div class="l-box title-wrap l-flex">
            <h3 class="c-lead icon">契約情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>代理登録</label>
              </div>
              <div class="c-inputWrap__items">
                {{ proxyReserveInfo }}
                <br />
                <div class="c-inputWrap__items__cols onTop l-area">
                  <div>
                    <div>
                      <dl class="c-dl l-align">
                        <dt class="w24">マニフェスト閲覧対象排出事業者：</dt>
                        <dd>
                          {{ joinCompanyNameForReference }}
                        </dd>
                      </dl>
                      <dl class="c-dl l-align" v-if="canProxyReserve">
                        <dt class="w24">代理予約排出事業者：</dt>
                        <dd>
                          {{ joinCompanyName }}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>

    <AppFooter />
  </div>
</template>

<script>
import callApi from "./mixins/callApi";

export default {
  name: "collector",
  components: {
  },
  mixins: [callApi],
  data() {
    return {
      canProxyReserve: false,
      proxyReserveGenerateCompanies: [],
      proxyReferenceGenerateCompanies: [],
      errMsgs: [],
    };
  },
  computed: {
    isCollector() {
      return this.$store.getters["userInfo/isCollector"];
    },
    proxyReserveInfo() {
      return this.canProxyReserve ? '有' : '無'
    },
    joinCompanyName() {
      return this.proxyReserveGenerateCompanies.map((company) => company.name).join('、');
    },
    joinCompanyNameForReference() {
      return this.proxyReferenceGenerateCompanies.map((company) => company.name).join('、');
    }
  },
  methods: {
  },
  mounted() {
    this.getCollectorContractApi().then((data) => {
      this.canProxyReserve = data.canProxyReserve;
      this.proxyReserveGenerateCompanies = data.proxyReserveGenerateCompanies ?? [];
      this.proxyReferenceGenerateCompanies = data.collectReferenceGenerateCompanyList ?? [];
    });
  },
};
</script>
<style scoped>
.w24 {
  width: 24rem;
}
.l-align {
      align-items: flex-start;
}
</style>
