import axios from "axios";
import { API_URL, PAGE_PATH } from "@/const";

export default {
  methods: {
    getCollectorContractApi() {
      return axios
        .get(API_URL.COLLECTOR.CONTRACT)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err && err.response) {
            if (err.response.status === 403) {
              this.$router.push(PAGE_PATH.FORBIDDEN);
            }
          }
          console.log(err);
        });
    },
  },
};
